import {
  camera, scene, renderer, controls, // 3d 三大组件
  initCamera, initRenderer, initScene, initControls, // 初始化3d
  render, global3D, // 3d 其他
  objects, targets, cardSize, // 3d 变量
} from './3d-core.js';
export {
  camera, scene, renderer, controls, // 3d 三大组件
  initCamera, initRenderer, initScene, initControls, // 初始化3d
  render, global3D, // 3d 其他
  objects, targets, cardSize, // 3d 变量
}

import { transform, transformStatus, animate } from './3d-animate.js';
export { transform, transformStatus, animate };

import { create3DCard } from './3d-card-element.js';
import { targetsCoord } from './3d-card-coord.js';
import { initEvent } from './3d-bind-event.js';
export { rotateBall, rotateBallStop } from './3d-action.js';
import lotteryConfig from './lottery-config.js';
import axios from 'axios';

function init() {
  initCamera(); // 相机
  initScene(); // 场景

  // let formData = new FormData();
  // axios.post('/api/Lottery/employee', formData).then(response => { //加载抽奖人员
  //   let data = response.data.data;
  //   console.log('employee data->',data);
  //   let employeeList=[];
  //   data.forEach((item, index) => {
  //     let newItem = {
  //       count: 10,
  //       countRemain: 10,
  //       everyTimeGet: 10,
  //       name: item.prize,
  //       detail: item.goods,
  //       id: item.itemid,
  //       cardListWin: 0,
  //       round: 0
  //     };
  //     employeeList.push(newItem);
  //   });
  //   lotteryConfig.employeeList=employeeList; 
  // })

  create3DCard(); // 制作卡片3D对象的DOM
  targetsCoord(); // 计算table、sphere、helix、grid四个图形的坐标

  initRenderer(); // 渲染器
  initControls(); // 控制器

  // 防止DOM未加载 TODO 判断是否需要setTimeout
  setTimeout(() => {
    initEvent(); // 绑定事件
  }, 100)
}

export { init };
