import axios from 'axios';

const lotteryModel = {
  randomWinnerList: [],

  /* 随机抽出中奖人员 */
  getRandomWinner: async function (lotteryId, prizeId, turnId, groupId) {
    let formData = new FormData();
    formData.append("lottery_id", lotteryId);
    formData.append("prize_id", prizeId);
    formData.append("turn_id", turnId);
    formData.append("group_id", groupId);

    await axios.post('/api/Lottery/start', formData).then(response => { //加载奖项（轮次）
      let data = response.data.data;
      console.log('random winner data->',data);
      this.randomWinnerList=data;
    })
  },

  /* 获取抽奖列表 */
  getLotteryInfo: async function (lotteryId) {
    let formData = new FormData();
    formData.append("lottery_id", lotteryId);

    await axios.post('/api/Lottery/index', formData).then(response => { //加载奖项（轮次）
      let data = response.data.data;
      console.log('lottery info list->',data);
      this.randomWinnerList=data;
    })
  }
}

export default lotteryModel;

