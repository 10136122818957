<template>
  <div class="lottery-action">
    <div id="menu">
      <div style="margin-bottom: 10px;">
        <button id="table" v-show="showBtn">TABLE</button>
        <button id="sphere" v-show="showBtn">SPHERE</button>
        <button id="helix" v-show="showBtn">HELIX</button>
        <button id="grid" v-show="showBtn">GRID</button>
      </div>
      <div style="margin-bottom: 10px;">
        <button id="selectPrize" @click="showSelectPrizePanel=true">选择奖项</button>
        <button id="tableShow" @click="tableShow">展示全部</button>
        <!-- button  @click="playerList">抽奖名单</button -->
        <!--button id="winShow" @click="showWinnerPanel()">展示中奖</button-->
        <button id="reset" @click="resetData">重置数据</button>
      </div>
      <div>
        <button id="lotteryStart" @click="lotteryStart">开始抽奖</button>
        <button id="lotteryStop" @click="lotteryStop">停止滚动</button>
      </div>
    </div>

    <!-- 显示中奖者 -->
    <div class="show-all-win-user" v-if="showAllWinUserPanel">
      <span class="close-btn" @click="showAllWinUserPanel = false">✖</span>
      <div class="prize-win-item" v-for="(item, index) in prizeList" :key="index">
        <div class="prize-name">{{ item.name }}</div>
        <div class="prize-win-user">
<!--          <div class="prize-win-user-name"-->
<!--                v-for="(user, _index) in item.cardListWin" :key="_index">-->
<!--            {{ user.name }}-->
<!--            <br v-if="_index % 10 === 0 && _index !== 0"/>-->
<!--          </div>-->
          <!-- 每十个换行 -->
          <div class="prize-win-user-name-wrap" v-for="(arr, arrIndex) in getRenderArr(item.cardListWin)" :key="arrIndex">
            <span class="prize-win-user-name" v-for="(user, userIndex) in arr" :key="userIndex">
              {{ user.name }}
            </span>
            <br />
          </div>
        </div>
      </div>
    </div>

    <!-- 选择奖项分类 -->
    <div class="show-all-prize" v-if="showSelectPrizePanel">
      <span class="close-btn" @click="showSelectPrizePanel = false">✖</span>
      <div class="prize-item" v-for="(item, index) in prizeTypeList" :key="index">
        <button  @click="prizeRowClick(item,index)">{{ item.prizeName }}</button>
      </div>
    </div>

  </div>
</template>

<style lang="scss" scoped>
@import './lottery-action.scss';
</style>

<script>
import { Component, Vue } from "vue-property-decorator";
import { setSphereDist } from './3d-calc-distance.js';
import { transform, transformStatus } from './3d-animate.js';
import CONFIG from "../../config.js";
import lotteryConfig from './lottery-config.js';
import { cardFlyAnimation, rotateBall, rotateBallStop } from './3d-action.js';
import { getRandomCard,randomWinnerList } from './lottery-algorithm.js';
import STATUS from './3d-status.js';
import lotteryModel from '../../model/lottery-model.js';
import axios from 'axios';

@Component({
  components: {}
})
export default class Prize extends Vue {
  showBtn = false;
  showAllWinUserPanel = false;
  showSelectPrizePanel = false;
  //prizeList = lotteryConfig.prizeList;
  //prizeTypeList = lotteryConfig.prizeTypeList;
  getUserById = lotteryConfig.getUserById;
  getRenderArr(arr) {
    const arrRes = [];
    const n = 10;
    const len = arr.length;
    const lineNum = len % n === 0 ? len / n : Math.floor( (len / n) + 1 );
    for (let i = 0; i < lineNum; i++) {
      const temp = arr.slice(i*n, i*n+n);
      arrRes.push(JSON.parse(JSON.stringify(temp)));
    }
    return arrRes;
  }

  /* 奖项分类点击 */
  async prizeRowClick(item,index){
    console.log('item.prizeId->',item.prizeId,',',item.groupId);

    if(!STATUS.checkStatus('prizeRowClick')){
      return void 0;
    }

    let obj=document.querySelectorAll('.prize-wrap')[0];
    obj.setAttribute('style','background-color:rgb(67,0,0,0.55)');

    let objArr=document.querySelectorAll('.prize-item');
    objArr.forEach((item, index) => {
      item.setAttribute('style','display:none');
    });

    let objArr2=document.querySelectorAll('.prize-item[data-prize-id="'+item.prizeId+'"]');
    objArr2.forEach((item, index) => {
      item.setAttribute('style','display:block');
    });

    this.showSelectPrizePanel=false;
    let item0=objArr2[0];

    Prize.currentPrizeIndex = item0.dataset.prizeId;
    lotteryConfig.currentPrize = item0.dataset.prizeId;
    //await transform('table', 1000); // TODO重复点击处理
    await transform('table', 400);
    


    // let obj2=document.querySelectorAll('.lottery-header')[0];
    // obj2.setAttribute('style','display:none');

    STATUS.setStatusWait();
  }

  /* 开始-抽奖 */
  async lotteryStart() {
    if(!STATUS.checkStatus('lotteryStart')){
      return void 0;
    }
    const currentPrize = lotteryConfig.getCurrentPrize();
    console.log('currentPrize->',currentPrize);
    if (!currentPrize) {
      alert('请选择奖项');
      return void 0;
    }
    if (currentPrize.countRemain <= 0) {
      alert(lotteryConfig.getCurrentPrize().name + '已经抽取完毕，请选择其他奖项');
      return void 0;
    }

    // 先回到table状态再抽奖
    STATUS.setStatusRun();
    transformStatus !== 'table' && await transform( 'table', 500 );
    await transform( 'sphere', 300 );
    rotateBall();
    STATUS.setCanStop();

    if(!CONFIG.WS_ENABELD)return;
    let actions = {'action':'cmd-start-successful','params':{'msg':''}}; //告诉服务端抽奖已开始
    this.websocketsend(JSON.stringify(actions));
  }

  /* 停止-选出中奖 */
  async lotteryStop(ev) {

    if(!STATUS.checkStatus('lotteryStop')){
      return void 0;
    }

    if (STATUS.getStatus() != STATUS.CAN_STOP) {
      alert('尚未开始抽奖');
      return void 0;
    }

    const currentPrize = lotteryConfig.getCurrentPrize();
    if (!currentPrize) {
      alert('请选择奖项');
      return void 0;
    }

    STATUS.setStatusRun();
    rotateBallStop();
    
    await lotteryModel.getRandomWinner(currentPrize.lotteryId,currentPrize.prizeId,currentPrize.id,currentPrize.groupId);
    const cardSelect = getRandomCard(currentPrize); //生成客户端随机中奖人员
    console.log('cardSelect->',cardSelect);

    /* 将服务端的中奖人员信息填充到卡片 */
    let winnerList=lotteryModel.randomWinnerList;
    for(let i=0;i<cardSelect.length;i++){
      let obj=document.querySelector('.element[data-card-id="'+cardSelect[i].id+'"]');
      //console.log('obj->',obj);
      let img=obj.querySelector('img');
      img.src='img/avatar/'+winnerList[i].employee_code+'.JPG'
      //img.src=winnerList[i].avatar;
      let title=obj.querySelector('.symbol');
      title.textContent=winnerList[i].employee_name;
      let details=obj.querySelector('.details');
      details.textContent=winnerList[i].department;
    }

    const cardSelectIndex = cardSelect.map(_ => _.index);
    console.log('lotteryConfig->',lotteryConfig);
    await setSphereDist(2, 500);
    await cardFlyAnimation(cardSelectIndex);
    STATUS.setStatusWait();

    if(!CONFIG.WS_ENABELD)return;
    let actions = {'action':'cmd-stop-successful','params':{'msg':''}}; //告诉服务端抽奖已结束
    this.websocketsend(JSON.stringify(actions));
  }

  /* 展示全部 */
  async tableShow() {
    if(!STATUS.checkStatus('tableShow')){
      return void 0;
    }

    STATUS.setStatusRun();
    await transform( 'table', 1000 ); // sphere
    STATUS.setStatusWait();
  }

  playerList(){
    window.location.href='./#/player';
  }

  /* 重置数据 */
  resetData () {
    if(!STATUS.checkStatus('resetData')){
      return void 0;
    }

    if (confirm('是否要重置所有抽奖数据，此操作不可恢复！')) {
      lotteryConfig.clearLocalStorage();
      location.reload();
    }
  }

  mounted () {
    this.$bus.$on('lottery-3d-init', () => {
      STATUS.setStatusWait();
    });

    /* 加载奖项分类 */
    let formData = new FormData();
      axios.post('/api/Lottery/prize', formData).then(response => {
      let data = response.data.data;
      console.log('prizeTypeList data->',data);
      this.prizeTypeList=[];
      data.forEach((item, index) => {
        let newItem = {
          lotteryId:item.lottery_id,
          prizeId:item.prize_id,
          groupId:item.group_id,
          prizeName:item.prize_name,
          prizePicture:item.prize_picture
        };
        this.prizeTypeList.push(newItem);
      });
      lotteryConfig.prizeTypeList=this.prizeList;
    })

    if(!CONFIG.WS_ENABELD)return;
    setInterval(this.websocketHeartbeat,30000); //维持心跳
  }

  created() {
    if(!CONFIG.WS_ENABELD)return;
    this.initWebSocket(); 
  }

  destroyed() {
    if(!CONFIG.WS_ENABELD)return;
    this.websock.close(); //离开路由之后断开websocket连接
  }

  initWebSocket() { //初始化weosocket
    console.log('init websocket...');
    this.websock = new WebSocket(CONFIG.WS_URL);
    this.websock.onmessage = this.websocketonmessage;
    this.websock.onopen = this.websocketonopen;
    this.websock.onerror = this.websocketonerror;
    this.websock.onclose = this.websocketclose;
  }

  websocketHeartbeat(){ //心跳包
    let actions = {'action':'heartbeat','params':''}; 
    this.websocketsend(JSON.stringify(actions));
  }

  websocketonerror() { //连接建立失败重连
    console.log('websocket error!');
    this.initWebSocket();
  }

  websocketsend(Data) { //数据发送
    console.log('websocket send->',Data);
    this.websock.send(Data);
  }

  websocketclose(e) { //关闭
    console.log('websocket close. bye');
    console.log("断开连接", e);
  }

  websocketonopen() { //连接建立之后注册自己
    console.log('websocket init successful!');
    let actions = {'action':'terminus-connected','params':{'id':''}}; //注册被控端
    this.websocketsend(JSON.stringify(actions));
  }
  
  websocketonmessage(e) { //数据接收
    const redata = JSON.parse(e.data);
    console.log('redata->',redata);
    console.log('redata.action->',redata.action);
    if(redata.action=='cmd-start'){
      this.lotteryStart();
    }
    if(redata.action=='cmd-stop'){
      this.lotteryStop();
    }
    console.log('resonse data->',redata);
  }

}
</script>
