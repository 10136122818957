const CONFIG = {
    //http地址
    HTTP_URL:'https://lottery.yugucloud.com',
    //api地址
    API_URL:'https://lottery.yugucloud.com',
    //websocket地址
    WS_URL:'ws://lottery.yugucloud.com:2146',
    //开启websocket功能
    WS_ENABELD: true, 
    //图片根路径
    IMG_BASE_URL:'https://lottery.yugucloud.com',
    //JS根路径
    JS_BASE_URL:'https://lottery.yugucloud.com',
    //CSS根路径
    CSS_BASE_URL:'https://lottery.yugucloud.com'
	
    //http地址
    //HTTP_URL:'http://zs133.yugucloud.com',
    //api地址
    //API_URL:'http://zs133.yugucloud.com',
    //websocket地址
    //WS_URL:'ws://zs133.yugucloud.com:2346',
    //开启websocket功能
    //WS_ENABELD: true, 
    //图片根路径
    //IMG_BASE_URL:'http://zs133.yugucloud.com',
    //JS根路径
    //JS_BASE_URL:'http://zs133.yugucloud.com',
    //CSS根路径
    //CSS_BASE_URL:'http://zs133.yugucloud.com'

    // //http地址
    // HTTP_URL:'http://my.lottery.com',
    // //api地址
    // API_URL:'http://my.lottery.com',
    // //websocket地址
    // WS_URL:'ws://localhost:2346', 
    // //开启websocket功能
    // WS_ENABELD: false, 
    // //图片根路径
    // IMG_BASE_URL:'http://my.lottery.com',
    // //JS根路径
    // JS_BASE_URL:'http://my.lottery.com',
    // //CSS根路径
    // CSS_BASE_URL:'http://my.lottery.com'
}

export default CONFIG;