const INIT = 'init';
const RUNNING_LOTTERY = 'running'; //当前命令执行中
const WAIT_LOTTERY = 'wait'; //待命
const CAN_STOP = 'canStop'; //抽奖中，可以停

let lotteryStatus = INIT;

const getStatus = () => {
  return lotteryStatus;
}

const setStatus = (value) => {
  lotteryStatus = value;
}
const setStatusWait = (value) => {
  lotteryStatus = WAIT_LOTTERY;
  console.log('setStatusWait->', 'WAIT_LOTTERY');
}
const setStatusRun = (value) => {
  lotteryStatus = RUNNING_LOTTERY;
}
const setCanStop = (value) => {
  lotteryStatus = CAN_STOP;
  console.log('setCanStop->', 'CAN_STOP');
}
const isWait = () => {
  return lotteryStatus === WAIT_LOTTERY;
}
const isRun = () => {
  return lotteryStatus === RUNNING_LOTTERY;
}
const canStop = () => {
  return lotteryStatus === CAN_STOP;
}

/* 检查按钮状态 */
const checkStatus = (value) => {
  console.log('checkStatus->', value, 'getStatus()->', getStatus());

  switch (value) {
    case 'resetData': //重置数据
    case 'tableShow': //展示全部
    case 'selectPrize': //点击抽奖分类
    case 'prizeRowClick': // 点击奖项
    case 'lotteryStart': // 开始抽奖
      if (getStatus() == CAN_STOP) {
        alert('正在抽奖，请停止后再试！');
        return false;
      }
      if (getStatus() == RUNNING_LOTTERY) {
        alert('正在加载，请稍候再试！');
        return false;
      }
      break;
      
    case 'lotteryStop': // 抽出中奖者
      if (getStatus() == RUNNING_LOTTERY) {
        alert('正在加载，请稍候再试！');
        return false;
      }
      break;

    default: //默认
      alert('不支持该方法：' + value);
      return false;
  }
  return true;
}

const status = {
  getStatus,
  setStatus,

  checkStatus,

  setStatusWait,
  setStatusRun,
  setCanStop,
  
  isWait,
  isRun,
  canStop,
  WAIT_LOTTERY,
  RUNNING_LOTTERY,
  INIT,
  CAN_STOP
}
export { lotteryStatus }
export default status;
