













































import { Component, Vue } from "vue-property-decorator";
import Lottery3d from './lottery-3d.vue';
import Prize from './lottery-prize.vue';
import LotteryMusic from './lottery-music.vue';
import lotteryStarfield from './lottery-starfield.vue';
import lotteryConfig from './lottery-config.js';

@Component({
  components: {
    Lottery3d, Prize, LotteryMusic, lotteryStarfield
  }
})
export default class Lottery extends Vue {
  headerTitle = lotteryConfig.headerTitle;
  mounted() {
    
  }
}
