
































import { Component, Vue } from "vue-property-decorator";
import axios from 'axios';
import { transform } from './3d-animate.js';
import STATUS from './3d-status.js';
import lotteryConfigImport from './lottery-config.js';
const lotteryConfig: any = lotteryConfigImport;
import lotteryAction from './lottery-action.vue';

@Component({
  components: { lotteryAction }
})
export default class Prize extends Vue {
  $bus: any;
  showBtn = false;
  prizeList = lotteryConfig.prizeList;
  prizeTypeList=lotteryConfig.prizeTypeList;
  currentPrizeIndex = null;
  donePrizeIndex = null;

  /* 选择奖项分类 */
  async selectPrize(prize, index) {
    console.log('selectPrize->',prize);
    if(!STATUS.checkStatus('selectPrize')){
      return void 0;
    }

    STATUS.setStatusRun();
    this.currentPrizeIndex = index;
    lotteryConfig.currentPrize = prize.id;
    await transform('table', 400);
    STATUS.setStatusWait();
  }
  
  mounted() {
    lotteryConfig.getLocalStorage();

    const currentPrize = lotteryConfig.getCurrentPrize()
    if (!currentPrize) {
      //this.currentPrizeIndex = this.prizeList.length - 1;
      //lotteryConfig.currentPrize = this.prizeList[this.prizeList.length - 1]['id'];
    } else {
      const index = lotteryConfig.prizeList.findIndex(_ => _.id === currentPrize.id);
      this.currentPrizeIndex = index;
    }
    
    if(lotteryConfig.prizeList.length==0){
      let formData = new FormData();
      axios.post('/api/Lottery/turn', formData).then(response => { //加载奖项轮次
        let data = response.data.data;
        console.log('turn data->',data);
        this.prizeList=[];
        data.forEach((item, index) => {
          let newItem = {
            count: item.num,
            countRemain: item.num,
            //everyTimeGet: item.num,
            everyTimeGet: item.numPerTurn,
            name: item.prize,
            detail: item.goods,
            id: item.itemid,
            cardListWin: [],
            round: 0,
            lotteryId: item.lottery_id,
            prizeId: item.prize_id,
            groupId: item.group_id
          };
          this.prizeList.push(newItem);
        });
        console.log('this.prizeList->',this.prizeList);
        lotteryConfig.prizeList=this.prizeList;
        lotteryConfig.setLocalStorage();
      })
    }else{
      this.prizeList=lotteryConfig.prizeList;
    }

  }
}
